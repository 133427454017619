import NtajEpk from './NTAJ/NTAJ-epk';

const EPK = () => {
  return (
    <div>
      <NtajEpk />
    </div>
  );
};

export default EPK;