import { MessageType, MixcloudMixType } from '../types/componentTypes';
import videos from "../assets/data/videos.json";

const endpoint = process.env.REACT_APP_API_ENDPOINT;
const MIXCLOUD_URL = 'https://api.mixcloud.com/';
const usernames = {
  objobj: 'objectobjectobject',
  NTAJ: 'notthatalexjones'
}

if (!endpoint) {
  throw new Error('REACT_APP_API_ENDPOINT environment variable is not defined');
}

let baseUrl: string = endpoint;

async function getMixes(user: 'objobj' | 'NTAJ') {
  // WHILE OBJOBJ MIXCLOUD DOESNT EXIST - REMOVE!
  user = 'NTAJ';

  const delimiter = usernames[user];

  const response = await fetch(`${MIXCLOUD_URL}${usernames[user]}/cloudcasts`);
  const body = await response.json()
  return body.data.map((MixcloudMix: MixcloudMixType) => {
    const { url, name, created_time, pictures } = MixcloudMix;
    return {
      name: name,
      url: url.split(delimiter)[1].replace(/\//g, ''),
      cover: pictures.extra_large,
      date: created_time,
    };
  }).reverse().slice(-5);
}

const getVideos = async () => {
  // const response = await fetch(`${baseUrl}/videos`);
  // return response.json();
  return videos;
}

const postMessage = async (message: MessageType) => {
  const response = await fetch (`${baseUrl}/contact`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });
  return response;
}

export { getMixes, getVideos, postMessage };