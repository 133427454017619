import React, { useState, useEffect } from 'react';
import { ComponentProps } from '../../../types/componentTypes';
import cloud from '../../../assets/images/cloud.png';

import './NTAJ.scss';

const NTAJ: React.FC<ComponentProps> = ({ AppContainer }) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const cloudies = new Array(25).fill(new Array(25).fill(cloud));

  useEffect(() => {
    if (AppContainer.current) {
      const AppContainerCurrent = AppContainer.current;
      const handleScroll = () => {
        if (!isScrolling) {
          setIsScrolling(true);

          // This timeout should match the 'shake' effect length in the scss file
          setTimeout(() => setIsScrolling(false), 2000);
        }
      };

      AppContainerCurrent.addEventListener('scroll', handleScroll);

      return () => {
        AppContainerCurrent.removeEventListener('scroll', handleScroll);
      };
    }
  }, [AppContainer, isScrolling]);
  

  return (
    <div className='ntaj-container'>
      {cloudies.map((clouds, rowIndex) => (
        <div key={rowIndex}>
          {clouds.map((cld: string, index: number) => {
            // const rand = (0.9 + Math.random() * 0.2) * 100;
            return (
              <img
                key={index}
                src={cld}
                alt='cloud'
                // style={{ height: `${rand}%`}}
                className={isScrolling ? 'shaking' : ''}
              />
            )
          })}
        </div>
      ))}
    </div>
  );
}

export default NTAJ;