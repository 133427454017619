import React, { useEffect, useRef, useState } from 'react';
import Hammer from 'hammerjs';
import '../../styles/main.scss';
import './Photos.scss';
import DJ1 from '../../assets/images/DJ1.jpg';
import DJ2 from '../../assets/images/DJ2.gif';
import DJ3 from '../../assets/images/DJ3.jpg';
import DJ4 from '../../assets/images/DJ4.jpg';
import DJ5 from '../../assets/images/DJ5.jpeg';

const Photos: React.FC = () => {
  const [photos, setPhotos] = useState([
    { url: DJ5, rotation: getRandomRotation() },
    { url: DJ4, rotation: getRandomRotation() },
    { url: DJ3, rotation: getRandomRotation() },
    { url: DJ2, rotation: getRandomRotation() },
    { url: DJ1, rotation: getRandomRotation() },
  ]);

  const PhotosContainer = useRef<HTMLDivElement | null>(null);
  const photoElementsRefs = useRef<HTMLImageElement[]>([]);

  function getRandomRotation() {
    return Math.floor(Math.random() * 31) - 15;
  }

  useEffect(() => {
    const topPhotoElement = photoElementsRefs.current[photos.length - 1];
    const topPhotoRotation = photos[photos.length - 1].rotation;

    if (topPhotoElement) {
      const hammer = new Hammer(topPhotoElement);
      hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });

      const reorderPhotos = () => {
        setPhotos((prevPhotos) => [
          prevPhotos[prevPhotos.length - 1],
          ...prevPhotos.slice(0, prevPhotos.length - 1),
        ]);
      };


      let endX: number;
      let endY: number;

      const handlePanMove = (event: HammerInput) => {
        const { deltaX, deltaY } = event;
        endX = deltaX;
        endY = deltaY
        topPhotoElement.style.transform = `rotate(${topPhotoRotation}deg) translate(${deltaX}px, ${deltaY}px)`;
      };

      const handlePanEnd = () => {
        const threshold = 150;
        if (Math.abs(endX) > threshold || Math.abs(endY) > threshold) {
          reorderPhotos();
        }
        const time = 100;
        topPhotoElement.style.transition = `transform ${time / 1000}s ease`;
        topPhotoElement.style.transform = `rotate(${topPhotoRotation}deg) translate(0, 0)`;
        setTimeout(() => {
          topPhotoElement.style.transition = '';
        }, time);
      };

      hammer.on('panmove', handlePanMove);
      hammer.on('panend', handlePanEnd);

      return () => {
        hammer.off('panmove', handlePanMove);
        hammer.off('panend', handlePanEnd);
        hammer.destroy();
      };
    }
  }, [photos]);

  return (
    <div id='photos' ref={PhotosContainer}>
      {photos.map((photo, index) => (
        <img
          key={photo.url}
          ref={(el) => {
            if (el) photoElementsRefs.current[index] = el;
          }}
          src={photo.url}
          alt='Some DJ'
          style={{
            transform: `rotate(${photo.rotation}deg)`,
            position: 'absolute',
          }}
        />
      ))}
    </div>
  );
};

export default Photos;
