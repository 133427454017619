import React from "react";
import "./Nav.scss";
import NavIcon from "./NavIcon";
import { ComponentProps } from '../../types/componentTypes';

const env = process.env.REACT_APP_ENV;

const Nav: React.FC<ComponentProps> = ({ NTAJ, switchMood }) => {
  const navLinks = [
    { name: 'Mixes', padding: true },
    // { name: 'Videos', padding: false },
    { name: 'About', padding: true },
    { name: 'Photos', padding: true },
    { name: 'Contact', padding: false },
  ];

  return (
    <nav className='nav'>
      <div>
        {navLinks.map((link, index) => {
          const scrollTo = () => {
            const clickedElement = document.getElementById(
              link.name.toLowerCase()
            );
            if (clickedElement) {
              clickedElement.scrollIntoView({ behavior: 'smooth' });
              if (link.padding)
                clickedElement.style.paddingTop = `${window.innerHeight}px`;
              setTimeout(() => {
                clickedElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'end',
                });
              }, 1000);
            }
          };
          return <NavIcon key={index} title={link.name} onClick={scrollTo} />;
        })}
      </div>

      {env === 'development' && <button className='mood-switch-button' onClick={() => switchMood!()}>
        🕺🏻
      </button>}

      {NTAJ && <span className="ntaj-nav">NTAJ</span>}
    </nav>
  );
};

export default Nav;