import React, { useEffect, useState, useRef } from 'react';
import Mix from '../../components/Mix/Mix';
import MixPlayer from '../../components/Mix/MixPlayer';

import { getMixes } from '../../services/API';

import { ComponentProps, MixType } from '../../types/componentTypes';

import '../../styles/main.scss';
import './Mixes.scss';

const Mixes: React.FC<ComponentProps> = ({ AppContainer, NTAJ }) => {
  const [mixes, setMixes] = useState<MixType[]>([]);
  const [selectedMix, setSelectedMix] = useState<MixType | null>(null);
  const [unselectedMixes, setUnselectedMixes] = useState<MixType[] | null>(null);
  const [mixElements, setMixElements] = useState<HTMLElement[] | []>([]);
  const [animationDone, setAnimationDone] = useState<boolean>(false);
  const [mixcloudUrl, setMixcloudUrl] = useState<string>('');

  const MixesRef = useRef<HTMLDivElement>(null);

  const includeMiniPlayer = () => {
    return +window.innerWidth >= 768 ? 0 : 1;
  }

  // Mixes Fan Effect
  useEffect(() => {
    const mixes = document.getElementsByClassName('mix-container');
    setMixElements(mixes as unknown as HTMLElement[]);

    const baseIndex = 2;

    setAnimationDone(false);

    setTimeout(() => {
      if (!selectedMix) {
        for (let i = 0; i < mixElements.length; i++) {
          const verticalDistance = (i - baseIndex) * 2 - 2;
          const mix = mixElements[i] as HTMLElement;
          mix.style.transform = `translateY(${verticalDistance}rem) rotateX(-${i * 5}deg)`;
        }
      }

      requestAnimationFrame(checkTransformsApplied);

    }, 1000);

    const checkTransformsApplied = () => {
      let allTransformsApplied = false;

      for (let i = 0; i < mixElements.length; i++) {
        const mix = mixElements[i] as HTMLElement;
        const transformValue = mix.style.transform;

        if (transformValue !== 'none' || transformValue.includes('rotateX')) {
          allTransformsApplied = true;
          break;
        }
      }

      if (allTransformsApplied) {
        setAnimationDone(true);
      } else {
        requestAnimationFrame(checkTransformsApplied);
      }
    };

  }, [mixElements, selectedMix]);

  // Retrieve Mixes
  useEffect(() => {
    async function fetchMixes() {
      try {
        const user = NTAJ ? 'NTAJ' : 'objobj';
        const mixes = await getMixes(user);
        mixes.forEach((mix: MixType) => {
          const [date] = mix.date.toString().split('-');

          return mix.date = date;
        });
        setMixes(mixes);
        // const mixcloudusername = NTAJ ? 'notthatalexjones' : 'objectobjectobject';
        const mixcloudusername = 'notthatalexjones';
        setMixcloudUrl(`https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&light=0&hide_artwork=1&mini=${includeMiniPlayer()}&feed=%2F${mixcloudusername}%2FMIX_NAME%2F`);

      } catch (error) {
        console.error(error);
      }
    }

    fetchMixes();
  }, [NTAJ]);

  // Set scroll listener so mixes follow scroll position
  useEffect(() => {
    if (AppContainer.current && MixesRef.current) {
      const AppContainerCurrent = AppContainer.current;
      const handleMoveMixesWithScroll = () => {
        const scrollPosition = AppContainer.current!.scrollTop;
        if (scrollPosition <= window.innerHeight) {
          MixesRef.current!.style.paddingTop = `${scrollPosition}px`;
        }
      };

      if (!selectedMix) {
        AppContainerCurrent!.addEventListener('scroll', handleMoveMixesWithScroll);

        return () => {
          AppContainerCurrent!.removeEventListener('scroll', handleMoveMixesWithScroll);
        };
      } else {
        AppContainer.current!.removeEventListener('scroll', handleMoveMixesWithScroll);
      }
    }
  }, [AppContainer, MixesRef, selectedMix]);

  const handleSelectMix = (selectedMix: MixType | null) => {
    setSelectedMix(selectedMix);

    if (!selectedMix) {
      setUnselectedMixes(null);
    } else {
      const index = mixes.findIndex((mix) => mix.title === selectedMix!.title);
      const unselectedMixes = [
        ...mixes.slice(0, index),
        ...mixes.slice(index + 1),
      ];
      if (AppContainer.current!) {
        if (MixesRef.current) MixesRef.current.style.paddingTop = '0px';
        AppContainer.current!.scrollTop = 0;
      }
      setUnselectedMixes(unselectedMixes);
    }
  };

  if (selectedMix && unselectedMixes) {
    return (
      <div id='mixes'>
        <div className='selected-mix'>
          <MixPlayer
            title={selectedMix.title}
            url={selectedMix.url}
            cover={selectedMix.cover}
            date={selectedMix.date}
            handleSelectMix={handleSelectMix}
          />
        </div>
        <div className='unselected-mixes'>
          {unselectedMixes.map((mix, index) => {
            return (
              <Mix
                key={index}
                index={index}
                title={mix.title}
                url={mixcloudUrl.replace('MIX_NAME', mix.url)}
                cover={mix.cover}
                date={mix.date}
                readyToAnimate={animationDone}
                handleSelectMix={handleSelectMix}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div id='mixes' ref={MixesRef}>
        {mixes.map((mix, index) => {
          return (
            <Mix
              key={index}
              index={index}
              title={mix.title}
              url={mixcloudUrl.replace('MIX_NAME', mix.url)}
              cover={mix.cover}
              date={mix.date}
              readyToAnimate={animationDone}
              handleSelectMix={handleSelectMix}
            />
          );
        })}
      </div>
    );
  }
};

export default Mixes;
