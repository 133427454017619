import React from 'react';
import { ComponentProps } from '../../types/componentTypes';
import ObjRepeat from './ObjRepeat/ObjRepeat';
import NTAJ_BG from './NTAJ/NTAJ';

import './Background.scss';

const ObjObjBackground: React.FC<ComponentProps> = ({ AppContainer, NTAJ }) => {
  return (
    <div className='background-container'>
      {NTAJ ? <NTAJ_BG AppContainer={AppContainer}/> : <ObjRepeat />}
    </div>
  );
};

export default ObjObjBackground;