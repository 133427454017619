import React, { useEffect, useRef, useState } from 'react';
import Div100vh from 'react-div-100vh';
import Background from './pages/Background/Background';
import Nav from './components/Nav/Nav';
import Mixes from './pages/Mixes/Mixes';
import Videos from './pages/Videos/Videos';
import About from './pages/About/About';
import Photos from './pages/Photos/Photos';
import Contact from './pages/Contact/Contact';
import EPK from './pages/EPK/epk';
import './styles/main.scss';

const App: React.FC = () => {

  const AppContainer = useRef<HTMLDivElement | null>(null);
  const [epk, setEpk] = useState(false);
  const [NTAJ, setNTAJ] = useState(false);
  const [docRoot, setDocRoot] = useState<HTMLElement | null>(null);


  // Theme toggle
  const switchMood = () => {
    return NTAJ ? setObjObjTheme() : setNTAJTheme();
  }

  useEffect(() => {
    setDocRoot(document.documentElement);
  }, []);

  useEffect(() => {
    const host = window.location.host;
    // const host = 'ntaj';
    if (host.includes('ntaj')) {
      setNTAJTheme();
    } else {
      setObjObjTheme();
    }
    
    const path = window.location.pathname;
    if (path.includes('epk')) setEpk(true);
  }, [docRoot]);

  const setNTAJTheme = () => {
    if (docRoot) {
      if (!docRoot.classList.contains('NTAJ')) {
        docRoot.classList.add('NTAJ');
      }
    }
    setNTAJ(true);
  }

  const setObjObjTheme = () => {
    if (docRoot) {
      if (docRoot.classList.contains('NTAJ')) {
        docRoot.classList.remove('NTAJ');
      }
    }
    setNTAJ(false);
  };



  return (
    <Div100vh>
      {epk ? (
        <EPK />
      ) : (
        <div id='App' ref={AppContainer}>
          <Background AppContainer={AppContainer} NTAJ={NTAJ} />
          <Nav AppContainer={AppContainer} switchMood={switchMood} NTAJ={NTAJ} />
          <Mixes AppContainer={AppContainer}/>
          {/* <Videos /> */}
          <About AppContainer={AppContainer} NTAJ={NTAJ} />
          <Photos />
          <Contact AppContainer={AppContainer} NTAJ={NTAJ} />
        </div>
      )}
    </ Div100vh>
  );
}

export default App;
