import React, { useEffect, useRef, useState } from "react";
import sanitizeHtml from 'sanitize-html';

import "../../styles/main.scss";
import "./About.scss";
import { ComponentProps } from "../../types/componentTypes";

import bio from '../../assets/images/bio.png';

const About: React.FC<ComponentProps> = ({ AppContainer, NTAJ }) => {

  const AboutContainer = useRef<HTMLDivElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  
  // Set About divs
  const objBio = [
    '[object Object]',
    'Not confined by genres and fascinated by the full spectrum of electronic music. Exploring relationships between texture rhythm, and blending exciting and introspective sounds alike.',
    'An eclectic taste and collection developed through years of crate digging and label sourcing from time based in Barcelona, travelling throughout Europe and influenced by roots in the UK, allowing to harmonise the obscure with the familiar to craft an immersive and dynamic musical journey.',
  ];

  const NTAJBio = [
    'Not that Alex Jones - Yeah, <i>that</i> Not That Alex Jones.',
    'Climbing out of the Welsh valleys and dropping into Barcelona, Alex (this one) has made his home at two ends of a musical spectrum. Joining those two extremes is what he\'s all about: professional, but still playful; authentic, but always accessible; serving up sets that are at once eclectic and cohesive.',
    'The focus is on bringing sunshine to even the most subterranean dancefloors, and he\'ll draw on a dozen different genres to do it. From garage to jazz - and with all sorts of weird and wonderful little gems along the way - Not That Alex Jones will satisfy the collectors and the weekend warriors alike. Great music, great energy, and plenty of surprises.',
    "Just don't confuse him with the infowars guy.",
  ];

  const objBioRefs = useRef(
    objBio.map((text) => ({
      ref: React.createRef<HTMLDivElement>(),
      text,
    }))
  );

  const NTAJBioRefs = useRef(
    NTAJBio.map((text) => ({
      ref: React.createRef<HTMLDivElement>(),
      text,
    }))
  );

  const [activeCursorRef, setActiveCursorRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    
    const revealText = () => {
      if (NTAJ) return; // Don't run the typewriter effect for NTAJ
      let accumulatedDelay = 0;

      objBioRefs.current.forEach((paragraph) => {
        if (paragraph.ref.current) {
          const characters: NodeListOf<HTMLParagraphElement> =
            paragraph.ref.current.querySelectorAll('p');

          let totalParagraphDelay = 0;

          characters.forEach(
            (char: HTMLParagraphElement, charIndex: number) => {
              const charDelay = Math.random() * 100;

              setTimeout(() => {
                requestAnimationFrame(() => {
                  if (activeCursorRef) {
                    char.insertAdjacentElement('afterend', activeCursorRef);
                    if (charIndex < characters.length - 1) {
                      activeCursorRef.style.animation = 'none';
                      void activeCursorRef.offsetHeight;
                      activeCursorRef.style.animation = '';
                    }
                    char.style.visibility = 'visible';
                  }
                });
              }, accumulatedDelay + totalParagraphDelay);

              totalParagraphDelay += charDelay;
            }
          );

          accumulatedDelay += totalParagraphDelay;
        }
      });
    };

    const handleTypeWriterAnimation = () => {
      if (AppContainer.current && AboutContainer.current) {
        const viewportHeight = window.innerHeight;
        const currentScrollPosition = AppContainer.current!.scrollTop;
        const MixesContainer = AboutContainer.current.previousElementSibling;
        let selectedMix = false;

        const isSelectedMix = () => {
          const MixesContainerStyle = window.getComputedStyle(MixesContainer!);
          return parseInt(MixesContainerStyle.paddingTop) > 0;
        }

        if (MixesContainer) {
          selectedMix = isSelectedMix();
        }

        const scrollPointToReach = selectedMix ? viewportHeight * 2 : viewportHeight * 1; 
        const containerInView = currentScrollPosition >= scrollPointToReach;

        if (containerInView) {
          revealText();
          AppContainer.current!.removeEventListener(
            'scroll',
            handleTypeWriterAnimation
          );
        }
      }
    }

    AppContainer.current!.addEventListener('scroll', handleTypeWriterAnimation);

  }, [AppContainer, AboutContainer, activeCursorRef]);

  useEffect(() => {
    if (canvasRef.current && NTAJ && AboutContainer.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      // Load images
      const img = new Image();
      img.src =
        'https://www.shutterstock.com/image-photo/hand-painted-watercolor-background-wash-600nw-691440016.jpg';

      // Draw function
      function draw() {
        ctx!.clearRect(0, 0, canvas.width, canvas.height);
        ctx!.globalCompositeOperation = 'source-over';
        ctx!.drawImage(img, 0, 0, canvas.width, canvas.height);
        ctx!.globalCompositeOperation = 'destination-in';
        const rect = AboutContainer.current!.getBoundingClientRect();
        const maskTop = rect.y;
        const maskLeft = rect.x;
        const maskWidth = rect.width;
        const maskHeight = rect.height;
        ctx!.fillRect(maskTop, maskLeft, maskWidth, maskHeight);
        ctx!.globalCompositeOperation = 'source-over';
      }

      img.onload = () => {
          draw();
      };

      const handleResize = () => {
        draw();

      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [AboutContainer, NTAJ]);

  return (
    <div id='about' ref={AboutContainer}>
      {NTAJ && <div className='ntaj-conatiner'>
        <img
          src={bio}
          alt='watercolour-gradient-background'
        />
        
      </div>}
      {!NTAJ && objBioRefs.current.map((element, index) => (
        <span key={index} ref={element.ref}>
          {Array.from(element.text).map((char, index) => (
            <p key={index}>{char}</p>
          ))}
        </span>
      ))}
      {!NTAJ && <span className='cursor' ref={(el) => { if (el) setActiveCursorRef(el) }}></span>}
    </div>
  );
}

export default About;